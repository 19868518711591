import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Markdown from "react-markdown";
import Moment from "react-moment";

import replaceIcons from "../components/functions/global";

import Banner from "../components/banner";

import Recruiting from "../components/recruiting";

export const query = graphql`
	query TeamQuery($slug: String!) {
		strapiTeam(slug: { eq: $slug}){
			slug
			sort
			seo {
				metaTitle
				metaDescription
				shareImage {
					url
				}
			}
			banner {
				title
				description
			}
			longDescription
			videos {
				title
				videoid
				platform
			}
			game {
				name
				short
				logo {
					alternativeText
					caption
					url
				}
			}
			title
			recruiting
		}
		allStrapiPlayer(filter: {team: {slug: {eq: $slug}}}, sort: {fields: role___sort, order: ASC}) {
			nodes {
				aktive
				gamertag
				longDescription
				person {
					birthdate
					avatar {
						alternativeText
						caption
						url
					}
					firstname
					lastname
					socialmedias {
						name
						platform
						url
					}
					country
				}
				role {
					name
					game
					sort
				}
				shortDescription
				videos {
					platform
					title
					videoid
				}
				team {
					slug
				}
			}
		}
		allStrapiTournament(filter: {team: {slug: {eq: $slug}}}, sort: {fields: dateStart, order: DESC}) {
			nodes {
				title
				slug
				description
				dateStart
				dateEnd
				game {
					logo {
						alternativeText
						caption
						url
					}
					name
					short
				}
				url
				image {
					alternativeText
					caption
					url
				}
				country {
					flag {
						alternativeText
						caption
						url
					}
					name
					short
				}
				team {
					slug
				}
				result
			}
		}
		allStrapiCountry {
			edges {
				node {
					flag {
						alternativeText
						caption
						url
					}
					name
					short
					strapiId
				}
			}
		}
		site {
			siteMetadata {
				imagePrefix
			}
		}
	}
`;

const TeamPage = ({ data }) => {
	const ateam = data.strapiTeam;
	const players = data.allStrapiPlayer;
	const tournaments = data.allStrapiTournament;
	const countries = data.allStrapiCountry;
	const site = data.site;

	var sectionIsAlt = false;
	var sectionStyle = 0;
	var styleClassName = 'style1';
	var firstVideo = true;

	var tmpAge = 0;

	ateam.longDescription = replaceIcons(ateam.longDescription);

	return (
		<Layout page={ateam.slug} seo={ateam.seo}>
			<Banner banner={ateam.banner}/>

			<section id="wrapper">
				{sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true}
				<section id="four" className={sectionIsAlt ? "wrapper alt style1" : "wrapper style1"}>
					<div className="inner">
						<a href="/teams" id="back-button" className="special back">back to teams</a>
						<h2 className="major">Team</h2>
						<img className="title" src={site.siteMetadata.imagePrefix + ateam.game.logo.url} alt={ateam.game.logo.alternativeText}/>
						<h3>{ateam.title && ateam.title !== '' ? ateam.title : ateam.game.name}</h3>
						{ateam.longDescription !== null ?
							<Markdown source={ateam.longDescription} escapeHtml={false} />
						: ''}
						{ateam.videos.length ? <h2 className="major">Videos</h2> : '' }
						{ateam.videos.length ?
							<div className="row videos adjust-height">
								{ateam.videos.map((avideo, i) => {
									if (avideo.videoid !== null && avideo.videoid !== '') {
										return (
											<div className={firstVideo ? "col-12 mb-2" : "col-12 col-md-4 mb-2"}>
												{firstVideo ? firstVideo = false : ''}
												{(() => {
													switch (avideo.platform) {
														case 'Youtube':
															return (<iframe width="100%" src={"https://www.youtube-nocookie.com/embed/"+avideo.videoid} title={"video-"+i} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"></iframe>);
														case 'Twitch':
															var tmpParent = site.siteMetadata.parentUrl;
															tmpParent = tmpParent.replace('https://','');
															return (<iframe width="100%" src={"https://player.twitch.tv/?video="+avideo.videoid+"&parent="+tmpParent} title={"video-"+i} frameBorder="0" scrolling="no" allowFullScreen="allowfullscreen"></iframe>);
														default:
															return (<iframe width="100%" src={avideo.videoid} title={"video-"+i} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"></iframe>);
													}
												})()}
											</div>
										);
									}
									return '';
								})}
							</div>
						: '' }
					</div>
				</section>

				{(() => {
					var count = 4;
					var nextExists = false;
					var currExists = false;
					if (tournaments.nodes.length) {
						sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
						return (
							<section id="four" className={sectionIsAlt ? "wrapper alt style2" : "wrapper style2"}>
								<div className="inner">
									<h2 className="major">Tournaments</h2>
									<h3>Next tournaments</h3>
									{tournaments.nodes.map((atournament, i) => {
										let tmpDate = new Date(atournament.dateStart);
										if (tmpDate > Date.now()) {
											nextExists = true;
											return (
												<p>{atournament.url !== null ? <a href={atournament.url} target="_blank" rel="noreferrer">{atournament.title}</a> : atournament.title} starting on <strong><Moment format="dddd, D MMMM YYYY">{atournament.dateStart}</Moment></strong></p>
											);
										}
										return '';
									})}
									{nextExists ? '' : <p>None at the moment. Will get posted.</p>}
									<h3>Current tournaments | <a href={"/teams/"+ateam.slug+"/tournaments"}>all</a></h3>
									<ul>
										{tournaments.nodes.map((atournament, i) => {
											let tmpDate = new Date(atournament.dateStart);
											let tmpDateEnd = new Date(atournament.dateEnd);
											if ((tmpDate <= Date.now() && tmpDateEnd > Date.now()) || atournament.dateStart === null) {
												currExists = true;
												return (
													<li>{atournament.dateStart ? <strong><Moment format="DD MMMM YYYY">{atournament.dateStart}</Moment></strong> : '' } {atournament.title}{" "+atournament.result}, <a href={"/teams/"+ateam.slug+"/tournaments/"+atournament.slug}>more</a></li>
												);
											}
											return '';
										})}
									</ul>
									{currExists ? '' : <p>None at the moment. Will get posted.</p>}
									<h3>Past tournaments | <a href={"/teams/"+ateam.slug+"/tournaments"}>all</a></h3>
									<ul>
										{tournaments.nodes.map((atournament, i) => {
											let tmpDate = new Date(atournament.dateStart);
											let tmpDateEnd = new Date(atournament.dateEnd);
											if (tmpDate <= Date.now() && tmpDateEnd <= Date.now() && count >= 0 && atournament.dateStart) {
												count = count - 1;
												return (
													<li>{atournament.dateStart ? <strong><Moment format="DD MMMM YYYY">{atournament.dateStart}</Moment></strong> : '' } {atournament.title}{atournament.result ? " "+atournament.result : ''}, <a href={"/teams/"+ateam.slug+"/tournaments/"+atournament.slug}>more</a></li>
												);
											}
											return '';
										})}
									</ul>
								</div>
							</section>
						);
					} else {
						return ('');
					}
				})()}

				{players.nodes.map((aplayer, i) => {
					if (aplayer.aktive) {
						sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
						sectionStyle >= 4 ? sectionStyle = 1 : sectionStyle += 1;
						switch (sectionStyle) {
							case 1: styleClassName = 'style1';
								break;
							case 2: styleClassName = 'style2';
								break;
							case 3: styleClassName = 'style3';
								break;
							case 4: styleClassName = 'style4';
								break;
							default: styleClassName = 'style1';
								break;
						}
						tmpAge = -1;
						if (aplayer.person.birthdate !== null){
							let tmpYear = new Date(aplayer.person.birthdate);
							let tmpNow = new Date();
							let tmpMonth = tmpNow.getMonth() - tmpYear.getMonth();
							tmpAge = tmpNow.getFullYear() - tmpYear.getFullYear();
							if (tmpMonth < 0 || (tmpMonth === 0 && tmpNow.getDate() < tmpYear.getDate())){
								tmpAge--;
							}
						}
						return (
							<section id={aplayer.gamertag} className={sectionIsAlt ? "wrapper alt spotlight "+styleClassName : "wrapper spotlight "+styleClassName}>
								<div className="inner">
									{aplayer.person && aplayer.person.avatar && aplayer.person.avatar.url !== null && aplayer.person.avatar.url !== '' ?
										<a href="#nothing" className="image align-self-start"><img src={site.siteMetadata.imagePrefix + aplayer.person.avatar.url} alt={aplayer.person.avatar.alternativeText} /></a>
									: ''}
									<div className="content">
										<h2 className="major">
											{aplayer.person.firstname +" '"+ aplayer.gamertag +"' "+ aplayer.person.lastname}
										</h2>
										<h3 className="mb-2">
											{aplayer.role && aplayer.role.name !== '' ? "Role: "+aplayer.role.name : ''}{aplayer.role && aplayer.role.name !== '' ? <br/> : ''}
											{aplayer.person.birthdate && aplayer.person.birthdate !== '' && tmpAge !== null && tmpAge >= 0 ? "Age: "+tmpAge: ''}{aplayer.person.birthdate && aplayer.person.birthdate !== '' && tmpAge !== null && tmpAge >= 0 ? <br/> : ''}
											{aplayer.person.country && aplayer.person.country !== '' ?
												countries.edges.map((acountry, i) => {
													if (acountry.node.strapiId === aplayer.person.country) {
														return (<span style={{ fontWeight: 'inherit', }}>Country: {acountry.node.name} <img className="inline-flag" src={site.siteMetadata.imagePrefix + acountry.node.flag.url} alt={acountry.node.flag.alternativeText} /></span>);
													} else { return '';}
												})
											: ''}
											{aplayer.person.country && aplayer.person.country !== '' ? <br/> : ''}
										</h3>
										{aplayer.longDescription !== null ?<Markdown source={aplayer.longDescription} escapeHtml={false} />:''}
										{aplayer.person.socialmedias.length ?
											<h3 className="title mb-2">Follow him on social medias</h3>
										: ''}
										{aplayer.person.socialmedias.length ?
											<div className={sectionIsAlt ? "d-flex justify-content-start social-player mb-4" : "d-flex justify-content-end social-player mb-4"}>
												{aplayer.person.socialmedias.map((socialmediaicon, i) => (
													<a href={socialmediaicon.url} target="_blank" rel="noreferrer">
														{(() => {
															switch (socialmediaicon.platform) {
																case 'Twitter':
																	return (<i className="fab fa-twitter-square"></i>);
																case 'Facebook':
																	return (<i className="fab fa-facebook-square"></i>);
																case 'Instagram':
																	return (<i className="fab fa-instagram-square"></i>);
																case 'Discord':
																	return (<i className="fab fa-discord"></i>);
																case 'Youtube':
																	return (<i className="fab fa-youtube-square"></i>);
																case 'Twitch':
																	return (<i className="fab fa-twitch"></i>);
																default:
																	return (<i className="fas fa-external-link-square-alt"></i>);
															}
														})()}
													</a>
												))}
											</div>
										:
											''
										}
										{aplayer.videos.length ?
											<h3>Videos</h3>
										: ''}
										{aplayer.videos.length ?
											<div className="row videos">
												{aplayer.videos.map((avideo, i) => {
													if (avideo.videoid !== null && avideo.videoid !== '') {
														return (
															<div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-2">
																{(() => {
																	switch (avideo.platform) {
																		case 'Youtube':
																			return (<iframe width="100%" src={"https://www.youtube-nocookie.com/embed/"+avideo.videoid} title={"video-"+i} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"></iframe>);
																		case 'Twitch':
																			var tmpParent = site.siteMetadata.parentUrl;
																			tmpParent = tmpParent.replace('https://','');
																			return (<iframe width="100%" src={"https://player.twitch.tv/?video="+avideo.videoid+"&parent="+tmpParent} title={"video-"+i} frameBorder="0" scrolling="no" allowFullScreen="allowfullscreen"></iframe>);
																		default:
																			return (<iframe width="100%" src={avideo.videoid} title={"video-"+i} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"></iframe>);
																	}
																})()}
															</div>
														);
													} else {
														return '';
													}
												})}
											</div>
										: ''}
									</div>
								</div>
							</section>
						);
					} else {return '';}
				})}

				{(() => {
					if (ateam.recruiting) {
						sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
						return (
							<Recruiting alt={sectionIsAlt}/>
						);
					} else {
						return ('');
					}
				})()}
			</section>
		</Layout>
	);
};

export default TeamPage;
